import create from 'zustand';

const useStore = create((set) => ({
  // 상태 값들
  hubLat: null,
  hubLng: null,
  mapX: null,
  mapY: null,
  request: null,
  searchAddressName: null,
  keyword: '',

  // 상태 업데이트 함수들
  setHubLat: (lat) => set({ hubLat: lat }),
  setHubLng: (lng) => set({ hubLng: lng }),
  setMapX: (x) => set({ mapX: x }),
  setMapY: (y) => set({ mapY: y }),
  setRequest: (requestValue) => set({ request: requestValue }),
  setSearchAddressName: (address) => set({ searchAddressName: address }),
  setKeyword: (kwd) => set({ keyword: kwd }),

  // 상태 초기화 함수 (필요할 경우)
  resetState: () => set({
    hubLat: null,
    hubLng: null,
    mapX: null,
    mapY: null,
    request: null,
    searchAddressName: null,
    keyword: null,
  }),
}));

export default useStore;