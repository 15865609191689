import React from 'react';
import { HashRouter, useRoutes } from 'react-router-dom';
import AddressSearch from './AddressSearch';
import MapApp from './MapApp';
import InputName from './InputName';

function App() {
  return (
    <HashRouter>
        <AppRoutes />
    </HashRouter>
  );
}

const AppRoutes = () => {
  const routes = useRoutes([
    { path: "/", element: <AddressSearch /> },
    { path: "/map", element: <MapApp /> },
    { path: "/inputname", element: <InputName /> },

  ]);
  return routes;
};

export default App;