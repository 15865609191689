import { IconButton, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';

const SearchBar = ({ keyword, setKeyword, handleSearch, handleClear, handleMapClick }) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      value={keyword}
      onChange={(e) => setKeyword(e.target.value)}
      placeholder="주소를 입력하세요"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {keyword && (
              <InputAdornment position="end">
                <IconButton onClick={handleClear} edge="end">
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )}
            <InputAdornment position="end">
              <IconButton onClick={handleMapClick} edge="end">
                <MapOutlinedIcon />
              </IconButton>
            </InputAdornment>
          </>
        ),
      }}
      sx={{
        backgroundColor: 'white',  // 입력 필드 배경색을 흰색으로 설정
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'black',  // 테두리 색상 설정
          },
          '&:hover fieldset': {
            borderColor: 'black',  // hover 시 테두리 색상 유지
          },
          '&.Mui-focused fieldset': {
            borderColor: 'black',  // focus 시 테두리 색상 유지
          },
        },
        '& input': {
          backgroundColor: 'white',  // 글씨 입력 시 배경색을 흰색으로 고정
        },
        // Chrome 자동 완성 스타일 제거
        '& input:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 1000px white inset !important',  // 자동 완성 배경색 덮어쓰기
          backgroundColor: 'white !important',
          color: '#000 !important',  // 자동 완성된 텍스트 색상을 검정색으로 설정
        },
        '& input:-webkit-autofill:focus': {
          WebkitBoxShadow: '0 0 0 1000px white inset !important',
          backgroundColor: 'white !important',
        },
        '& input:-webkit-autofill:hover': {
          WebkitBoxShadow: '0 0 0 1000px white inset !important',
          backgroundColor: 'white !important',
        },
        '& input:-webkit-autofill:active': {
          WebkitBoxShadow: '0 0 0 1000px white inset !important',
          backgroundColor: 'white !important',
        }
      }}
      style={{ marginBottom: '1rem', marginTop: '1rem' }}
    />
  );
};

export default SearchBar;