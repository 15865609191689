import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, TextField, Button, Box, Container } from '@mui/material';
import BackButton from './components/BackButton';

import CrossFrame from "./crossFrame";

const InputName = ({ crossFrameInstance }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // 입력된 값을 관리할 상태 선언
  const [inputValue, setInputValue] = useState('');
  
  const instanceRef = useRef({crossFrame: null});

  const onCrossFrameInitial = useCallback(() => {
    const instance = instanceRef.current;
    instance.crossFrame?.destroy();

    instance.crossFrame = null;
    instance.crossFrame = new CrossFrame();
  }, []);

  useEffect(() => {
    console.log('addressSearch useEffect');
    onCrossFrameInitial();
  }, [onCrossFrameInitial]);

  // 입력 변경 핸들러
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // 저장 버튼 클릭 핸들러
  const handleSave = () => {
    if (inputValue.trim() === '') {
      alert('장소 이름을 입력해주세요.');
      return;
    }

    const responseJson = location.state;

    responseJson.originalAddressName = location.state.addressName;
    responseJson.addressName = inputValue;

    console.log('save : ', responseJson);

    instanceRef.current.crossFrame.emit('iframe_to_parent', responseJson, function(err, result) {
      console.log('[frame.html] iframe_to_parent callback', 'err:', err, 'result:', result);
    });
    navigate(-1);
  };

  return (
    <Container maxWidth="xs" style={{ paddingTop: '1rem', textAlign: 'left', marginLeft: 0, height: '100vh' }}>
      <BackButton />
      {/* 타이틀 */}
      <Typography variant="h6" gutterBottom align="center">
        즐겨 찾는 장소 이름
      </Typography>

      {/* 입력 필드 */}
      <TextField
        label="즐겨 찾는 장소 이름"
        variant="outlined"
        fullWidth
        value={inputValue}
        onChange={handleInputChange}
        style={{ marginBottom: '1rem',marginTop: '1rem' }}
        sx={{ mb: 3 }}
      />

      {/* 저장 버튼 */}
      <Button 
        variant="contained" 
        color="primary" 
        fullWidth 
        onClick={handleSave}
      >
        저장
      </Button>
    </Container>
  );
};

export default InputName;