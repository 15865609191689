import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

function BackButton( { exitCallback, callback }) {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (typeof exitCallback === 'function') {
      // exitCallback이 존재할 경우 해당 콜백 실행
      exitCallback();
    } else {
      // exitCallback이 없을 경우 기본적으로 callback 호출
      if (typeof callback === 'function') {
        callback();
        navigate(-1);
      } else {
        // callback이 없으면 이전 페이지로 이동
        navigate(-1);
      }
    }
  };

  return (
    <IconButton
      onClick={handleGoBack}
      style={{
        position: 'absolute',      // 맵 위에 위치하도록 절대 위치 지정
        top: '10px',               // 상단에서 10px 아래
        left: '10px',              // 왼쪽에서 10px 오른쪽
        backgroundColor: '#fff',   // 버튼 배경색 (명확하게 보이도록 하얀색)
        borderRadius: '50%',       // 둥근 버튼
        padding: '8px',           // 버튼 크기를 조정하는 패딩
        zIndex: 1000,              // 맵보다 위에 표시되도록 z-index 설정
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // 그림자 효과 추가
      }}
    >
      <ArrowBackIosNewOutlinedIcon/>
    </IconButton>
  );
}

export default BackButton;