import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Alert,
  Paper,
  Box,
  CircularProgress
} from '@mui/material';

import CrossFrame from "./crossFrame";

import MapOutlinedIcon from '@mui/icons-material/MapOutlined';

import useStore from './store';

import BackButton from './components/BackButton';
import SearchBar from './components/SearchBar';

function AddressSearch() {
  const instanceRef = useRef({crossFrame: null});
  // const [address, setAddress] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);  // 로딩 상태를 관리하는 변수

  const initUrl = new URL(window.location.href);
  const queryString = new URLSearchParams(initUrl.search);
  const hub_lat = queryString.get('hub_lat');
  const hub_lng = queryString.get('hub_lng');
  const request = queryString.get('request');
  const lat = queryString.get('lat');
  const lng = queryString.get('lng');
  const hostAddressName = queryString.get('hostAddressName');

  const { setHubLat, setHubLng, setRequest, setSearchAddressName, setKeyword, keyword } = useStore();

  const onCrossFrameInitial = useCallback(() => {
    const instance = instanceRef.current;
    instance.crossFrame?.destroy();

    instance.crossFrame = null;
    instance.crossFrame = new CrossFrame();
  }, []);

  useEffect(() => {
    if (hub_lat) {
      setHubLat(hub_lat);
    }
    if (hub_lng) {
      setHubLng(hub_lng);
    }
    if (request) {
      setRequest(request);
    }


    // if (lat && lng && request ==='modify') {
    //   console.log('useEffect it is modify lat : ', lat, ' lng : ', lng);
    //   setTimeout(() => {
    //     navigate('/map', { state: { x: lng, y :lat, mapOnly : true } });
    //   }, 2000);
    //   // navigate('/map', { state: { x: lng, y :lat, mapOnly : true } });
    // }

  }, [hub_lat, hub_lng, request, setHubLat, setHubLng, setRequest]);

   // 로딩 화면을 1초간 보여주기 위한 useEffect
   useEffect(() => {
    if (lat && lng && request === 'modify') {
      setLoading(true);  // 로딩 시작
      console.log('useEffect it is modify lat : ', lat, ' lng : ', lng);
      setTimeout(() => {
        setLoading(false);  // 1초 후 로딩 종료
        navigate('/map', { state: { x: lng, y: lat, mapOnly: true, hostAddressName } });
      }, 500);
    }
  }, [lat, lng, request, navigate, hostAddressName]);

  useEffect(() => {
    console.log('addressSearch useEffect');
    onCrossFrameInitial();
  }, [onCrossFrameInitial]);

  // 키워드 검색 URL
  const keywordUrl = 'https://dapi.kakao.com/v2/local/search/keyword.json';
  // 주소 검색
  const addressUrl = 'https://dapi.kakao.com/v2/local/search/address.json';
  const handleSearch = useCallback(async () => {
    console.log('handleSearch keyword : ', keyword)
    if (!keyword) {
      console.log('handleSearch keyword is empty');
      setSearchResults([]);
      setError('검색 결과가 없습니다.');
      return;
    }
    try {
      console.log('hub_lat : ', hub_lat, ' hub_lng :', hub_lng);
      const [keywordResponse, addressResponse] = await Promise.all([
        axios.get(keywordUrl, {
          headers: {
            Authorization: 'KakaoAK e638f175e56ecc1e8d826ef5713059f2',
          },
          params: {
            x: hub_lng,
            y: hub_lat,
            query: keyword,
          },
        }),
        axios.get(addressUrl, {
          headers: {
            Authorization: 'KakaoAK e638f175e56ecc1e8d826ef5713059f2',
          },
          params: {
            query: keyword,
          },
        })
      ]);

      // 두 개의 결과 합치기
      const keywordDocuments = keywordResponse.data.documents || [];
      const addressDocuments = addressResponse.data.documents || [];

      // 두 결과를 합침
      const combinedResults = [...keywordDocuments, ...addressDocuments];

      const prevSearchResult = searchResults;

      if (combinedResults.length > 0) {
        setSearchResults(combinedResults);
        setError(null);
      } else {
        if (keyword && prevSearchResult) { //검색도중에 빌 경우에 검색결과가 없다고 하니 추가.
          console.log('yuji search result');
          return;
        }
        setSearchResults([]);
        setError('검색 결과가 없습니다.');
      }
    } catch (error) {
      console.error('Error fetching keyword:', error);
      setSearchResults([]);
      setError('주소 검색 중 오류가 발생했습니다.');
    }
  }, [keyword, hub_lat, hub_lng, searchResults]);

  const handleSelect = (selectedPlace) => {
    const { place_name, x, y } = selectedPlace;
    setSearchAddressName(place_name);
    // navigate('/map', { state: { searchAddressName: place_name, x, y } });
    navigate('/map', { state: { x, y } });
  };

  const handleSetting = (selectedPlace) => {
    const { place_name, x, y, road_address_name, address_name } = selectedPlace;
    const responseJson = {command: 'complete', response: request, lat: Number(y), lng: Number(x), addressName: place_name, originalAddressName: road_address_name || address_name };
    if (request === 'favorite') {
      navigate('/inputname', { state : responseJson})
    } else {
      instanceRef.current.crossFrame.emit('iframe_to_parent', responseJson, function(err, result) {
        console.log('[frame.html] iframe_to_parent callback', 'err:', err, 'result:', result);
      });
    }
    clearValue();
  };

  const clearValue = () => {
    // 상태 초기화
    setKeyword('');
    setSearchResults([]);
    setError(null);
  }

  const handleMapClick = () => {
    console.log('map clicked!');
    if (!hub_lat || !hub_lng) {
      alert('허브의 위치가 전달되지 않아 사용할 수 없어요.')
    } else {
      //address_name 은 실제로 안쓰고 있음 map 에서
      navigate('/map', { state: { address_name: 'temp', x : hub_lng, y : hub_lat }});
    }

    //test code 
    // const hub_lat2 = "37.59658060059806";
    // const hub_lng2 = "126.87716767678005";
    
    // navigate('/map', { state: { address_name: 'temp', x : hub_lng2 , y :  hub_lat2} });

  };


  useEffect(() => {
    if (keyword.trim() !== '') {  // 공백 체크 추가
      console.log('keyword : ', keyword);
      handleSearch();
    } else {
      setSearchResults([]);  // 주소가 빈 경우 검색 결과 초기화
      setError(null);  // 오류 메시지 초기화
    }
      // handleSearch 를 의존성 배열에 넣으면 계속 불림 일단 막음
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  const handleExit = () => {
    console.log("handleExit");
    const responseJson = {command: 'exit'};
    instanceRef.current.crossFrame.emit('iframe_to_parent', responseJson, function(err, result) {
      console.log('[frame.html] iframe_to_parent callback', 'err:', err, 'result:', result);
    });
  };

  return (
    <Container maxWidth="xs" style={{ paddingTop: '1rem', textAlign: 'left', marginLeft: 0, height: '100vh', overflow: loading ? 'hidden' : 'auto'  }}>
      {/* 로딩 중일 때 CircularProgress 표시 */}
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh" overflow="hidden">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <BackButton exitCallback={handleExit} />
          <Typography variant="h6" gutterBottom align="center">
            {request === 'departure' ? "출발지" : "즐겨 찾는 장소"}
          </Typography>
          <SearchBar
            keyword={keyword}
            setKeyword={setKeyword}
            handleClear={() => setKeyword('')}
            handleMapClick={handleMapClick}
          />
          {/* {
            error || searchResults.length > 0 ? (
              <></>
            ) : (
              <Box sx={{ textAlign: 'right' }}>
                <MapOutlinedIcon
                  onClick={handleMapClick}
                  sx={{ cursor: 'pointer' }} // 클릭할 수 있도록 커서 변경
                />
              </Box>
            )
          } */}

          {error && <Alert severity="error" style={{ marginTop: '1rem' }}>{error}</Alert>}
          {searchResults.length > 0 && (
            <Paper elevation={3} style={{ marginTop: '1rem', borderRadius: '16px' }}>
              <List style={{
                maxHeight: 'calc(100vh - 190px)', // 브라우저 하단과의 간격 유지
                overflow: 'auto',
              }}>
                {searchResults.map((result, index) => (
                  <ListItem button key={index} onClick={() => handleSelect(result)}>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                          {result.place_name || result.road_address_name || result.address_name}
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography variant="body2">
                            {result.road_address_name || result.address_name}
                          </Typography>
                          {hub_lat && (
                            <Typography variant="caption" color="textSecondary">
                              {result.distance
                                ? (result.distance >= 1000
                                  ? `${(result.distance / 1000).toFixed(1)} km`
                                  : `${result.distance} m`)
                                : ''}
                            </Typography>
                          )}
                        </>
                      }
                    />
                    <Button variant="outlined" color="black" size="small" onClick={(e) => {
                      e.stopPropagation();
                      handleSetting(result);
                    }}>
                      설정
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}
        </>
      )}
    </Container>
  );
}

export default AddressSearch;