export default (function () {
  const EVENT_MESSAGE = "CF2_MESSAGE";
  const EVENT_READY = "CF2_READY";

  let webviewReady = false;
  let webviewReadyFns = [];
  let webview = getParameterByName("webview") === "1";
  if (webview) {
    document.addEventListener("message", function (e) {
      if (webviewReady === false && e.data === EVENT_READY) {
        webviewReady = true;
        webviewReadyFns.forEach(function (webviewReadyFn) {
          webviewReadyFn();
        });
      }
    });
  }

  function CrossFrame(el, opts) {
    this._opts = opts || {};

    if (typeof this._opts.delay !== "number") {
      this._opts.delay = 50;
    }

    this.onMessage = this.onMessage.bind(this);
    this._clear = this._clear.bind(this);
    this._debug = this._debug.bind(this);
    this.on = this.on.bind(this);
    this.emit = this.emit.bind(this);
    this.destroy = this.destroy.bind(this);
    this.ready = this.ready.bind(this);
    this._ready = this._ready.bind(this);
    this._post = this._post.bind(this);
    this._destroyed = false;
    this._posting = false;
    this._postNext = this._postNext.bind(this);
    this._postQueue = [];
    this._el = el;

    this._clear();

    if (webview) {
      if (document && document.addEventListener) {
        document.addEventListener("message", this.onMessage);
      }
    } else {
      if (window && window.addEventListener) {
        window.addEventListener("message", this.onMessage);
      }
    }

    if (webview) {
      onWebviewReady(
        function () {
          this._ready();
        }.bind(this)
      );
    } else {
      this._ready();
    }

    return this;
  }

  CrossFrame.prototype._clear = function () {
    this._eventlisteners = {};
    this._callbackFns = {};
    this._callbackId = 0;
    this._readyFns = [];
    this._isReady = false;

    if (webview) {
      if (document && document.removeEventListener) {
        document.removeEventListener("message", this.onMessage);
      }
    } else {
      if (window && window.removeEventListener) {
        window.removeEventListener("message", this.onMessage);
      }
    }
  };

  CrossFrame.prototype._debug = function () {
    if (window.DEBUG)
      console.log.bind(null, "[CrossFrame]").apply(null, arguments);
  };

  CrossFrame.prototype.onMessage = function (event) {
    if (!event.data || typeof event.data !== "string") return;
    if (event.data.indexOf(EVENT_MESSAGE) !== 0) return;

    let encodedStr = event.data;
    encodedStr = encodedStr.substr(EVENT_MESSAGE.length);
    encodedStr = hexDecode(encodedStr);
    let jsonObj = jsonToObj(encodedStr);

    if (jsonObj.type === "tx") {
      const callback = (...rest) => {
        let message = {
          type: "cb",
          args: rest,
          callbackId: jsonObj.callbackId,
        };
        this.postMessage(message);
      };
      let eventListeners = this._eventlisteners[jsonObj.event];
      if (eventListeners) {
        eventListeners.forEach(function (eventListener) {
          eventListener.call(eventListener, jsonObj.data, callback);
        });
      }
    } else if (jsonObj.type === "cb") {
      let callbackFn = this._callbackFns[jsonObj.callbackId];
      if (callbackFn) {
        callbackFn.apply(callbackFn, jsonObj.args);
      }
    }
  };

  CrossFrame.prototype.on = function (event, callback) {
    this._eventlisteners[event] = this._eventlisteners[event] || [];
    this._eventlisteners[event].push(callback);

    return this;
  };

  CrossFrame.prototype.emit = function (event, data, callback) {
    console.log('emit 1');
    let callbackId = null;
    if (typeof callback === "function") {
      console.log('emit 2');
      callbackId = ++this._callbackId;
      this._callbackFns[callbackId] = callback;
    }
    console.log('emit 3');
    let message = {
      type: "tx",
      event: event,
      data: data,
      callbackId: callbackId,
    };
    console.log('emit 4');
    this.postMessage(message);
    console.log('emit 5');
    return this;
  };

  CrossFrame.prototype.destroy = function () {
    this._clear();
    this._destroyed = true;
  };

  CrossFrame.prototype.ready = function (callback) {
    if (this._isReady) {
      callback();
    } else {
      this._readyFns.push(callback);
    }
  };

  CrossFrame.prototype._ready = function () {
    this._isReady = true;
    this._readyFns.forEach(function (readyFn) {
      readyFn();
    });
  };

  CrossFrame.prototype.postReady = function (message) {
    this._debug("postReady()", message);
    console.log('postReady 1');
    this._post(EVENT_READY);
  };

  CrossFrame.prototype.postMessage = function (message) {
    console.log('postMessage 1');
    this._post(EVENT_MESSAGE + hexEncode(objToJson(message)));
  };

  CrossFrame.prototype._post = function (message) {
    this._debug("_post()", message);
    console.log('_post 1');
    this._postQueue.push(message);
    this._postNext();
  };

  CrossFrame.prototype._postNext = function () {
    console.log('_postNext 1');
    if (this._destroyed) return;
    if (this._posting) return;
    console.log('_postNext 2');
    let message = this._postQueue.shift();
    console.log('_postNext 3 message : ', message);
    if (typeof message === "undefined") return;
    console.log('_postNext 4');
    let target;
    if (this._el) {
      target = this._el.contentWindow ? this._el.contentWindow : this._el;
      console.log('_postNext 5');
    } else if (window && window.parent) {
      target = window.parent;
      console.log('_postNext 6');
    }

    if (target) {
      console.log('_postNext 7');
      this._posting = true;
      target.postMessage(message, "*");
      console.log('_postNext 8');
      setTimeout(
        function () {
          this._posting = false;
          this._postNext();
        }.bind(this),
        this._opts.delay
      );
    }
  };

  function onWebviewReady(callback) {
    if (webviewReady) {
      callback();
    } else {
      webviewReadyFns.push(callback);
    }
  }

  // utils
  function getParameterByName(name, url) {
    if (window && window.location && window.location.href) {
      if (!url) url = window.location.href;
      // eslint-disable-next-line no-useless-escape
      name = name.replace(/[\[\]]/g, "\\$&");
      let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
    return "";
  }

  function objToJson(obj) {
    return JSON.stringify(obj, function replacer(key, value) {
      if (value instanceof Error) {
        return {
          type: "Error",
          data: value.message,
        };
      }

      return value;
    });
  }

  function jsonToObj(json) {
    return JSON.parse(json, function reviver(key, value) {
      if (value && value.type) {
        if (value.type === "Error") {
          return new Error(value.data);
        }
      }
      return value;
    });
  }

  function hexEncode(input) {
    let hex, i;
    let result = "";
    for (i = 0; i < input.length; i++) {
      hex = input.charCodeAt(i).toString(16);
      result += ("000" + hex).slice(-4);
    }
    return result;
  }

  function hexDecode(input) {
    let j;
    let hexes = input.match(/.{1,4}/g) || [];
    let back = "";
    for (j = 0; j < hexes.length; j++) {
      back += String.fromCharCode(parseInt(hexes[j], 16));
    }
    return back;
  }

  return CrossFrame;
})();
